import React from 'react';
import styled from 'styled-components';

import Breakpoints from '../../../constants/Breakpoints';
import { Checklist } from '../../Generic/Checklist';
import { Typography } from '../../Typography';

export const Benefits = () => {
  return (
    <BenefitsContainer>
      <HeaderText>
        <Typography variant="h2" color="primary" align="center">
          How can I benefit from recreational activities?
        </Typography>
        <Typography variant="p" color="primary" align="center">
          Recreational activities can benefit you in so many ways!
        </Typography>
        <Checklist
          itemArray={[
            'Increased confidence',
            'Improved quality of life',
            'Improved mood and energy',
            'Decreased social isolation',
            'Decreased feelings of anxiety and depression',
            'Becoming a part of a community',
            'Improved physical ability and stamina',
            'Increased social involved and frienships',
          ]}
        />
      </HeaderText>
    </BenefitsContainer>
  );
};

const BenefitsContainer = styled.div`
  padding: 2em;
  margin: auto;

  @media screen and (min-width: ${Breakpoints.medium}) {
    width: 80%;
  }
`;

const HeaderText = styled.div`
  margin: auto;

  @media screen and (min-width: ${Breakpoints.large}) {
    top: 10em;
  }
`;
