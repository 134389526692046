import React from 'react';
import styled from 'styled-components';

import Breakpoints from '../../../constants/Breakpoints';
import Colors from '../../../constants/Colors';
import { getSpacing } from '../../../constants/Spacing';
import { Icon } from '../../Generic/Icon';
import { Typography } from '../../Typography';

export const WhoCanHelp = () => {
  return (
    <WhoCanHelpContainer>
      <Typography variant="h2" color="primary" align="center">
        Who can help me?
      </Typography>

      <StyledGrid>
        <ResourceBox>
          <Icon icon="utensils" size="4x" />
          <Typography variant="h4" weight="xbold" color="primary">
            Attendant Services
          </Typography>
          <Typography variant="p" color="primary">
            Attendant Services can provide assistance with personal care and
            household management.
          </Typography>
        </ResourceBox>

        <ResourceBox>
          <Icon icon="bath" size="4x" />
          <Typography variant="h4" weight="xbold" color="primary">
            Personal Support Worker
          </Typography>
          <Typography variant="p" color="primary">
            Personal Support Workers (PSW) provide assistance with personal care
            and household management.
          </Typography>
        </ResourceBox>

        <ResourceBox>
          <Icon icon="list" size="4x" />
          <Typography variant="h4" weight="xbold" color="primary">
            Occupational Therapist
          </Typography>
          <Typography variant="p" color="primary">
            Occupational Therapists recommend adaptations to help clients
            maintain independence.
          </Typography>
        </ResourceBox>

        <ResourceBox>
          <Icon icon="users" size="4x" />
          <Typography variant="h4" weight="xbold" color="primary">
            Social Worker
          </Typography>
          <Typography variant="p" color="primary">
            Social workers assess your needs, connect you with resources and
            help you plan for your future.
          </Typography>
        </ResourceBox>

        <ResourceBox>
          <Icon icon="house-user" size="4x" />
          <Typography variant="h4" weight="xbold" color="primary">
            Home Support Worker
          </Typography>
          <Typography variant="p" color="primary">
            Home Support Workers (HSW) assist in household management
            activities.
          </Typography>
        </ResourceBox>

        <ResourceBox>
          <Icon icon="crutch" size="4x" />
          <Typography variant="h4" weight="xbold" color="primary">
            Physiotherapist
          </Typography>
          <Typography variant="p" color="primary">
            Physiotherapists assess physical functioning, and provide treament
            to maintain physical health.
          </Typography>
        </ResourceBox>

        <ResourceBox>
          <Icon icon="user-md" size="4x" />
          <Typography variant="h4" weight="xbold" color="primary">
            Primary Care Provider
          </Typography>
          <Typography variant="p" color="primary">
            Primary Care Providers care for basic medical needs and can refer to
            specialists as needed.
          </Typography>
        </ResourceBox>

        <ResourceBox>
          <Icon icon="dice" size="4x" />
          <Typography variant="h4" weight="xbold" color="primary">
            Recreation Therapist
          </Typography>
          <Typography variant="p" color="primary">
            Recreation Therapists assess abilities and interests, connect
            clients with community programs.
          </Typography>
        </ResourceBox>

        <ResourceBox>
          <Icon icon="comments" size="4x" />
          <Typography variant="h4" weight="xbold" color="primary">
            Speech-Language Pathologist
          </Typography>
          <Typography variant="p" color="primary">
            Speech-Language Pathologists assess and treat challenges with
            communication and swallowing.
          </Typography>
        </ResourceBox>
      </StyledGrid>
    </WhoCanHelpContainer>
  );
};

const WhoCanHelpContainer = styled.div`
  padding: 2em;
  margin: auto;

  @media screen and (min-width: ${Breakpoints.medium}) {
    width: 80%;
  }
`;

const ResourceBox = styled.div`
  background-color: ${Colors.pureWhite};
  border: 2px solid ${Colors.primary};
  border-radius: 4px;
  padding: ${getSpacing('3x')};
  color: ${Colors.primary};

  @media screen and (min-width: ${Breakpoints.medium}) {
    padding: ${getSpacing('8x')};
  }
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${getSpacing('3x')};

  margin-top: ${getSpacing('4x')};

  @media screen and (min-width: ${Breakpoints.medium}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
