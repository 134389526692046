import React from 'react';

import { Benefits } from './Benefits';
import { Main } from './Main';
import { RecreationCategories } from './RecreationCategories';

export const EducationRecreation = () => {
  return (
    <React.Fragment>
      <Main />
      <RecreationCategories />
      <Benefits />
    </React.Fragment>
  );
};
