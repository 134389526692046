import React from 'react';

import { Categories } from './Categories';
import { CTA } from './CTA';
import { Main } from './Main';

export const EducationHomepage = () => {
  return (
    <React.Fragment>
      <Main />
      <Categories />
      <CTA />
    </React.Fragment>
  );
};
