import React from 'react';

import { Funding } from './Funding';
import { FundingOptions } from './FundingOptions';
import { Main } from './Main';

export const EducationFinancial = () => {
  return (
    <React.Fragment>
      <Main />
      <Funding />
      <FundingOptions />
    </React.Fragment>
  );
};
