import React from 'react';
import styled from 'styled-components';

import Breakpoints from '../../constants/Breakpoints';
import { getSpacing } from '../../constants/Spacing';
import { Typography } from '../Typography';

import { Icon } from './Icon';

export interface Props {
  itemArray: string[];
}

export const Checklist: React.FC<Props> = ({ itemArray }) => {
  const listItems = itemArray.map((item) => (
    <TextWithIcon>
      <Icon icon="check-square" size="2x"></Icon>
      <Typography variant="p" color="primary" weight="xbold" inline>
        {item}
      </Typography>
    </TextWithIcon>
  ));

  return <StyledChecklist>{listItems}</StyledChecklist>;
};

const StyledChecklist = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;

  width: 80%;
  margin: auto;
`;

const TextWithIcon = styled.div`
  display: grid;
  grid-template-columns: ${getSpacing('6x')} 1fr;
  align-items: center;
`;
