import React from 'react';
import styled from 'styled-components';

import Breakpoints from '../../../constants/Breakpoints';
import Colors from '../../../constants/Colors';
import { getSpacing } from '../../../constants/Spacing';
import { Button } from '../../Generic/Button';
import { Icon } from '../../Generic/Icon';
import { Typography } from '../../Typography';

export const FundingOptions = () => {
  return (
    <FundingOptionsContainer>
      <Typography variant="h2" weight="xbold" color="primary" align="center">
        Learn about your funding options!
      </Typography>

      <StyledGrid>
        <ResourceBox>
          <Icon icon="house-user" size="4x" />
          <Typography variant="h4" weight="xbold" color="primary">
            Ontario Works
          </Typography>
          <Typography variant="large" weight="regular" color="primary">
            A government support program to help cover the basic costs of living
            and help clients find employment. It is available to Ontario
            residents 16+ in need of financial assistance.
          </Typography>
        </ResourceBox>

        <ResourceBox>
          <Icon icon="dollar-sign" size="4x" />
          <Typography variant="h4" weight="xbold" color="primary">
            Ontario Disability Support Program
          </Typography>
          <Typography variant="large" weight="regular" color="primary">
            A support program for Ontario residents 18+ with disabilities to
            help cover the costs of basic needs, shelter, and some health care
            costs.
          </Typography>
        </ResourceBox>

        <ResourceBox>
          <Icon icon="wheelchair" size="4x" />
          <Typography variant="h4" weight="xbold" color="primary">
            Grants
          </Typography>
          <Typography variant="large" weight="regular" color="primary">
            Grants can provide funding for a variety of needs, such as
            equipment, home modifications, transportation, education and other
            costs.
          </Typography>
        </ResourceBox>

        <ResourceBox>
          <Icon icon="hand-holding-usd" size="4x" />
          <Typography variant="h4" weight="xbold" color="primary">
            Employment
          </Typography>
          <Typography variant="large" weight="regular" color="primary">
            Consider planning for is paid employment. This can be part-time or
            full-time position. Build employment skills and find services.
          </Typography>
        </ResourceBox>
      </StyledGrid>
    </FundingOptionsContainer>
  );
};

const FundingOptionsContainer = styled.div`
  padding: 1em;
  margin: auto;

  @media screen and (min-width: ${Breakpoints.medium}) {
    width: 80%;
  }
`;

const ResourceBox = styled.div`
  background-color: ${Colors.pureWhite};
  border: 2px solid ${Colors.primary};
  border-radius: 4px;
  padding: ${getSpacing('3x')};

  @media screen and (min-width: ${Breakpoints.medium}) {
    padding: ${getSpacing('8x')};
  }
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${getSpacing('3x')};

  @media screen and (min-width: ${Breakpoints.medium}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
