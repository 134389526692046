import { useState } from 'react';

export default function useForm() {
  const [state, setState] = useState({});

  const handleChange = (e, questionType) => {
    e.persist();

    if (questionType) {
      // checkbox flow
      let tempState = state[e.target.name] || [];

      if (tempState.includes(e.target.value)) {
        tempState = tempState.filter((x) => x !== e.target.value);
      } else {
        tempState.push(e.target.value);
      }

      setState((state) => ({ ...state, [e.target.name]: tempState }));
    } else {
      // radio button flow
      setState((state) => ({
        ...state,
        [e.target.name]: e.target.value,
      }));
    }
  };

  return [state, handleChange];
}
