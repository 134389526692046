import React from 'react';
import styled from 'styled-components';

import { getSpacing } from '../../constants/Spacing';
import { Typography } from '../Typography';

export interface Props {
  photo: string;
  name: string;
  role: string;
}

export const PersonCard: React.FC<Props> = ({ photo, name, role }) => {
  return (
    <PersonCardContainer>
      <CirclePhoto src={photo} alt={`Photo of ${name}`} />
      <div>
        <Typography variant="h4" color="primary" styleRules={{ margin: 0 }}>
          {name}
        </Typography>
        <Typography variant="p" color="primary">
          {role}
        </Typography>
      </div>
    </PersonCardContainer>
  );
};

const PersonCardContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CirclePhoto = styled.img`
  display: block;
  max-width: 150px;
  max-height: 150px;
  margin: ${getSpacing('2x')};
`;
