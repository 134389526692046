import * as jsonpatch from 'fast-json-patch';
import React, { useMemo, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { authHeader, jsonHeaders } from '../../utils/headers';
import Table from '../Generic/Table';

export default function ResourceTable({ history }) {
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const originalDataRef = useRef(originalData);

  useEffect(() => {
    fetch('/api/resources/admin', { headers: authHeader() })
      .then((res) => res.json())
      .then((d) => {
        setData(d);
        setOriginalData(d);
        originalDataRef.current = d;
      })
      .catch((err) => {
        console.log(JSON.stringify(err));

        history.push('/');
        window.location.reload();
      });
  }, [history]);

  const updateResource = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      }),
    );
  };

  const resetData = () => setData(originalData);

  const archiveResource = (id) => {
    const indexToUpdate = originalDataRef.current.findIndex(
      (element) => element._id === id,
    );
    const resourceToUpdate = originalDataRef.current[indexToUpdate];
    fetch(`/api/resources/${resourceToUpdate._id}`, {
      headers: { ...authHeader(), ...jsonHeaders() },
      method: 'PATCH',
      body: JSON.stringify(
        jsonpatch.compare(resourceToUpdate, {
          ...resourceToUpdate,
          archived: !resourceToUpdate.archived,
        }),
      ),
    })
      .then((res) => res.json())
      .then((d) => {
        const temp = [...originalDataRef.current];
        temp[indexToUpdate] = d;
        setData(temp);
        setOriginalData(temp);
        originalDataRef.current = temp;
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
      });
  };

  const saveRow = (newResource) => {
    const indexToUpdate = originalDataRef.current.findIndex(
      (element) => element._id === newResource._id,
    );
    fetch(`/api/resources/${originalDataRef.current[indexToUpdate]._id}`, {
      headers: { ...authHeader(), ...jsonHeaders() },
      method: 'PATCH',
      body: JSON.stringify(
        jsonpatch.compare(originalDataRef.current[indexToUpdate], newResource),
      ),
    })
      .then((res) => res.json())
      .then((d) => {
        const temp = [...originalDataRef.current];
        temp[indexToUpdate] = d;
        setData(temp);
        setOriginalData(temp);
        originalDataRef.current = temp;
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Resource',
        columns: [
          {
            Header: 'Name',
            accessor: (row) => row.name || '',
            id: 'name',
          },
          {
            Header: 'Category',
            accessor: (row) => row.category || '',
            id: 'category',
          },
          {
            Header: 'Subtitle',
            accessor: (row) => row.subtitle || '',
            id: 'subtitle',
          },
        ],
      },
      {
        Header: 'Details',
        columns: [
          {
            Header: 'Link',
            accessor: (row) => row.link || '',
            id: 'link',
          },
          {
            Header: 'Description',
            accessor: (row) => row.description || '',
            id: 'description',
          },
          {
            Header: 'Age',
            accessor: (row) => row.age || '',
            id: 'age',
          },
          {
            Header: 'Condition',
            accessor: (row) => row.condition || '',
            id: 'condition',
          },
          {
            Header: 'Email',
            accessor: (row) => row.email || '',
            id: 'email',
          },
          {
            Header: 'Phone',
            accessor: (row) => row.phone || '',
            id: 'phone',
          },
          {
            Header: 'Funding',
            accessor: (row) => row.funding || '',
            id: 'funding',
          },
          {
            Header: 'Location',
            accessor: (row) => row.location || '',
            id: 'location',
          },
          {
            Header: 'Service Type',
            accessor: (row) => row.serviceType || '',
            id: 'serviceType',
          },
          {
            Header: 'Icon',
            accessor: (row) => row.icon || '',
            id: 'icon',
          },
          {
            Header: 'Recreation Activity (if applicable)',
            accessor: (row) => row.recreationActivity || '',
            id: 'recreationActivity',
          },
          {
            Header: 'Save',
            accessor: '_id',
            Cell: ({ cell }) => (
              <button
                value={cell.row.values}
                onClick={() => saveRow(cell.row.values)}
              >
                Save
              </button>
            ),
          },
          {
            Header: 'Archive',
            accessor: 'archived',
            Cell: ({ cell }) => (
              <input
                type="checkbox"
                checked={cell.row.values.archived}
                onClick={() => archiveResource(cell.row.values._id)}
              />
            ),
          },
        ],
      },
    ],
    [],
  );

  return (
    <>
      <button onClick={resetData}>Reset Data</button>
      <TableContainer>
        <Table columns={columns} data={data} updateResource={updateResource} />
      </TableContainer>
    </>
  );
}

const TableContainer = styled.table`
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  padding: 20px;

  table {
    border-spacing: 0;
    border: 1px solid #ededed;
  }
  table tr:last-child td {
    border-bottom: 0;
  }
  table th,
  table td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid #ededed;
    border-right: 1px solid #ededed;
    position: relative;
  }
  table th:last-child,
  table td:last-child {
    border-right: 0;
  }
  table tr:nth-child(even) {
    background-color: #fafafa;
  }

  table th::before {
    position: absolute;
    right: 15px;
    top: 16px;
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
  table th.sort-asc::before {
    border-bottom: 5px solid #22543d;
  }
  table th.sort-desc::before {
    border-top: 5px solid #22543d;
  }

  input {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: none;
  }
`;
