// Font Awesome Icon Setup
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import styled from 'styled-components';

import Breakpoints from '../constants/Breakpoints';

import { AboutUs } from './AboutUs';
import Login from './Auth/Login';
import Register from './Auth/Register';
import { EducationFinancial } from './Education/Financial';
import { EducationHealthcare } from './Education/Healthcare';
import { EducationHomepage } from './Education/Homepage';
import { EducationIndependentLiving } from './Education/IndependentLiving';
import { EducationRecreation } from './Education/Recreation';
import ScrollToTop from './Generic/ScrollToTop';
import { Homepage } from './Homepage';
import Footer from './Layout/Footer';
import Navigation from './Layout/Navigation';
import Profile from './Profile/Profile';
import ResourceTable from './Resources/AdminTable';
import Resources from './Resources/Resources';
import Survey from './Survey/Survey';

library.add(fas);

export default function App() {
  return (
    <div className="App">
      <Content>
        <Router
          basename={process.env.PUBLIC_URL}
          onUpdate={() => window.scrollTo(0, 0)}
        >
          <Navigation />
          <ScrollToTop />
          {/* <NotificationBar /> */}
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/resources" component={Resources} />
            <Route exact path="/resources/edit" component={ResourceTable} />
            <Route exact path="/survey" component={Survey} />

            <Route exact path="/education" component={EducationHomepage} />
            <Route
              exact
              path="/education/financial"
              component={EducationFinancial}
            />
            <Route
              exact
              path="/education/independent-living"
              component={EducationIndependentLiving}
            />
            <Route
              exact
              path="/education/healthcare"
              component={EducationHealthcare}
            />
            <Route
              exact
              path="/education/recreation"
              component={EducationRecreation}
            />

            <Redirect to="/" />
          </Switch>
          <Footer />
        </Router>
      </Content>
    </div>
  );
}

const Content = styled.div`
  margin-top: 5em;

  @media screen and (min-width: ${Breakpoints.medium}) {
    margin-top: 5em;
    min-height: 78vh;
  }
`;
