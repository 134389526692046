import React from 'react';
import styled from 'styled-components';

import Breakpoints from '../../constants/Breakpoints';
import { getSpacing } from '../../constants/Spacing';
import BackgroundImage from '../../images/AboutUs/floating-soccer.png';
import { ContentCard } from '../Generic/ContentCard';
import { Typography } from '../Typography';

export const WhatWeSupport = () => {
  return (
    <>
      <WhatWeSupportContainer>
        <TitleContainer>
          <Typography variant="h2" color="primary">
            What do we support?
          </Typography>
        </TitleContainer>

        <StyledGrid>
          <ContentCard
            icon="heart"
            title="Community"
            subText="Building connections between youth and local care organizations to strengthen the community."
          />
          <ContentCard
            icon="handshake"
            title="Collaboration"
            subText="Work with a network of care providers and youth with disabilities to exchange thoughts and resources."
          />
          <ContentCard
            icon="running"
            title="People First"
            subText="Prioritize the needs of our users and community first and foremost."
          />
          <ContentCard
            icon="eye"
            title="Clarity"
            subText="Provide resources that are clear, accessible and make the adult disability system easy to navigate."
          />
          <ContentCard
            icon="user"
            title="Independence"
            subText="Work to allow adults with disabilities to be able to live independently."
          />
          <ContentCard
            icon="peace"
            title="Diversity and Inclusion"
            subText="Create a space that is inclusive, accessible, safe, and empowering for everyone."
          />
          <ContentCard
            icon="check"
            title="Quality and Reliability"
            subText="Provide trusted resources that can be used by youths with disabilities, their families and care providers."
          />
        </StyledGrid>
      </WhatWeSupportContainer>
      <StyledImage src={BackgroundImage} alt="" />
    </>
  );
};

const WhatWeSupportContainer = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 8em;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${getSpacing('3x')};

  @media screen and (min-width: ${Breakpoints.medium}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: ${Breakpoints.xLarge}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const StyledImage = styled.img`
  object-fit: cover;
`;
