import React from 'react';

import Newsletter from '../Newsletter';

import { CTAs } from './CTAs';
import { Main } from './Main';
import { ResourceInfo } from './ResourceInfo';

export const Homepage = () => {
  return (
    <React.Fragment>
      <Main />
      <CTAs />
      <ResourceInfo />
      <Newsletter />
    </React.Fragment>
  );
};
