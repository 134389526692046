import {
  faInstagram,
  faFacebook,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Colors from '../../constants/Colors';
import LogoSrc from '../../images/logo.png';
import { Icon } from '../Generic/Icon';
import { Typography } from '../Typography';

export default function Footer() {
  return (
    <FooterContainer>
      <Logo src={LogoSrc} alt="Transition Space logo" />
      <Description>
        A safe space for youth with disabilties to learn more about the adult
        disability system and succesfully transition to adulthood.
      </Description>
      <Icons>
        <Icon icon={faInstagram} size="2x" color="white" />{' '}
        <Icon icon={faFacebook} size="2x" color="white" />{' '}
        <Icon icon={faLinkedin} size="2x" color="white" />
      </Icons>
      <Col1>
        <p>
          <strong>Our Categories</strong>
        </p>
        <p>Independent Living</p>
        <p>Recreation</p>
        <p>Finance</p>
        <p>HealthCare</p>
      </Col1>
      <Col2>
        <a href="https://docs.google.com/spreadsheets/d/1PLHTRD0geOmvODDgUTh4KB0fGk1EM7KoQhZSULJxUd0/edit?usp=sharing">
          <Typography>Explore Resources</Typography>
        </a>
        <Link to={'/'}>
          <Typography data-text="Home">Home</Typography>
        </Link>
      </Col2>
      <Typography
        weight="thin"
        styleRules={{ gridArea: 'copyright', margin: 'auto' }}
      >
        2022 Transition Space. All rights reserved.
      </Typography>
    </FooterContainer>
  );
}

const Logo = styled.img`
  width: 100%;
  max-width: 300px;
  grid-area: logo;
`;

const Description = styled(Typography)`
  grid-area: desc;
`;

const Icons = styled.div`
  grid-area: icons;
`;

const Col1 = styled.div`
  grid-area: col1;
`;

const Col2 = styled.div`
  grid-area: col2;
`;

const FooterContainer = styled.div`
  width: 100%;
  padding: 3em 0;
  display: grid;
  column-gap: 50px;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    '. logo logo col1 col2 col2 . .'
    '. logo logo col1 col2 col2 . .'
    '. desc desc col1 col2 col2 . .'
    '. desc desc col1 col2 col2 . .'
    '. desc desc col1 col2 col2 . .'
    '. icons icons . . . . .'
    '. copyright copyright copyright copyright copyright copyright .';

  background-color: ${Colors.baseColor};
  color: ${Colors.lightTextColor};

  a {
    text-decoration: none;
    color: ${Colors.lightTextColor};
  }
`;
