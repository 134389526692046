import React, { useState } from 'react';
import styled from 'styled-components';

import Colors from '../../constants/Colors';
import finIcon from '../../images/icons/finIcon.svg';
import hcIcon from '../../images/icons/hcIcon.svg';
import ilIcon from '../../images/icons/ilIcon.svg';
import recIcon from '../../images/icons/recIcon.svg';
import { CategoryNavigation } from '../Generic/CategoryNavigation';
import PageHeader from '../Generic/PageHeader';

import ResourceGrid from './ResourceGrid';

export default function Resources() {
  /*
    Resource Categories
    0: Financial
    1: Recreation
    2: Health care
    3: Independent living
  */
  const resources = [
    { label: 'Financial', key: 0, icon: finIcon },
    { label: 'Recreation', key: 1, icon: recIcon },
    { label: 'Health Care', key: 2, icon: hcIcon },
    { label: 'Independent Living', key: 3, icon: ilIcon },
  ];
  const [resourceCategory, setResourceCategory] = useState(0);

  return (
    <ResourcePage>
      <PageHeader text="Find the right resources for your needs" />
      <CategoryNavigation
        items={resources}
        category={resourceCategory}
        setCategory={setResourceCategory}
      />
      <ResourceGrid resourceCategory={resourceCategory} />
    </ResourcePage>
  );
}

const ResourcePage = styled.div`
  background: ${Colors.greyBackgroundColor};
`;
