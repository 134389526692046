import React from 'react';
import styled from 'styled-components';

import Colors from '../../constants/Colors';
import { getSpacing } from '../../constants/Spacing';
import { Typography } from '../Typography';

import { Button } from './Button';
import { Icon } from './Icon';

export interface Props {
  ctaText?: string;
  ctaLink?: string;
  icon?: string;
  imageSrc?: string;
  imageAlt?: string;
  subText?: string;
  title?: string;
}

export const ContentCard: React.FC<Props> = ({
  ctaText,
  ctaLink,
  icon,
  imageSrc,
  imageAlt,
  subText,
  title,
}) => {
  return (
    <StyledContentCard>
      {imageSrc && <StyledCCImage src={imageSrc} alt={imageAlt} />}
      {icon && <Icon icon={icon} />}
      <Typography variant="title" color="primary" weight="bold">
        {title}
      </Typography>
      <Typography variant="p" color="dark">
        {subText}
      </Typography>
      {ctaText && <Button dest={ctaLink} text={ctaText} variant="text" />}
    </StyledContentCard>
  );
};

const StyledContentCard = styled.div`
  min-width: 250px;
  border-radius: 5px;
  border: 2px solid ${Colors.primary};
  display: flex;
  flex-direction: column;
  padding: ${getSpacing('6x')};

  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
`;

const StyledCCImage = styled.img`
  max-width: 200px;
`;
