import React from 'react';
import { Element } from 'react-scroll';
import styled from 'styled-components';

import Colors from '../../../constants/Colors';
import { MarketingComponent } from '../../Generic/MarketingComponent';

export const CTA = () => {
  return (
    <CTAsOuterWrapper>
      <CTAsInnerWrapper>
        <MarketingComponent
          title="What's next?"
          text="Explore finances, healthcare, recreation and independent living related services and programs near you. Find the resources that meet your specific needs, future goals and are near you."
          image="waving-man"
          imagePosition={'left'}
          ctaText="Find resources"
          ctaLink="https://docs.google.com/spreadsheets/d/1PLHTRD0geOmvODDgUTh4KB0fGk1EM7KoQhZSULJxUd0/edit?usp=sharing"
        />
      </CTAsInnerWrapper>
    </CTAsOuterWrapper>
  );
};

const CTAsOuterWrapper = styled(Element)`
  background-color: ${Colors.greyBackgroundColor};
`;

const CTAsInnerWrapper = styled.div`
  width: 100%;
  max-width: 1600px;
  margin: auto;
`;
