import React from 'react';

import Newsletter from '../Newsletter';

import { Main } from './Main';
import { MeetTheTeam } from './MeetTheTeam';
import { OurSupporters } from './OurSupporters';
import { PressFeatures } from './PressFeatures';
import { VolunteerCTA } from './VolunteerCTA';
import { WhatWeDo } from './WhatWeDo';
import { WhatWeSupport } from './WhatWeSupport';

export const AboutUs = () => {
  return (
    <React.Fragment>
      <Main />
      <WhatWeDo />
      <WhatWeSupport />
      <MeetTheTeam />
      <OurSupporters />
      <VolunteerCTA />
      <PressFeatures />
      <Newsletter />
    </React.Fragment>
  );
};
