import React from 'react';
import { scroller } from 'react-scroll';
import styled from 'styled-components';

import Breakpoints from '../../../constants/Breakpoints';
import Colors from '../../../constants/Colors';
import { Typography } from '../../Typography';

export const Main = () => {
  return (
    <MainContainer>
      <HeaderText>
        <CTATitleContainer>
          <Typography variant="h1" color="light" align="center">
            Learn about the Adult Disability System
          </Typography>
        </CTATitleContainer>
        <CTATextContainer>
          <Typography variant="large" color="light" align="center">
            Select an option to learn more about a part of the disability
            system, and the community resources that may be available to you.
          </Typography>
        </CTATextContainer>
      </HeaderText>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  background: ${Colors.primary};
  text-align: center;
  padding: 2em;
`;

const HeaderText = styled.div`
  margin: auto;

  @media screen and (min-width: ${Breakpoints.large}) {
    top: 10em;
  }
`;

const CTATitleContainer = styled.div`
  h1 {
    font-size: 2rem;
  }

  @media screen and (min-width: ${Breakpoints.large}) {
    h1 {
      font-size: 3.75rem;
    }
  }
`;

const CTATextContainer = styled.div`
  margin-bottom: auto;

  @media screen and (min-width: ${Breakpoints.large}) {
    margin: auto;
    width: 40%;
  }
`;
