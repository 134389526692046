import React from 'react';
import { Element } from 'react-scroll';
import styled from 'styled-components';

import Colors from '../../../constants/Colors';
import { MarketingComponent } from '../../Generic/MarketingComponent';

export const Main = () => {
  return (
    <MainOuterWrapper>
      <MainInnerWrapper>
        <MarketingComponent
          title="Recreation"
          text="Recreational activities fall into four categories: physical, social, cognitive and emotional. All of these areas are important for personal growth and overall health and wellbeing."
          image="woman-on-bench"
          imagePosition={'right'}
          ctaText="Financial resources"
          ctaLink="https://docs.google.com/spreadsheets/d/1PLHTRD0geOmvODDgUTh4KB0fGk1EM7KoQhZSULJxUd0/edit?usp=sharing"
        />
      </MainInnerWrapper>
    </MainOuterWrapper>
  );
};

const MainOuterWrapper = styled(Element)`
  background-color: ${Colors.greyBackgroundColor};
`;

const MainInnerWrapper = styled.div`
  width: 80%;
  margin: auto;
`;
