import cn from 'classnames';
import React from 'react';

import './spacer.scss';

export interface Props {
  size: string;
  horizontal?: Boolean;
  vertical?: Boolean;
}

export const Spacer: React.FC<Props> = ({ size, horizontal, vertical }) => {
  return (
    <div
      className={cn({
        [`spacer--size-${size}`]: size,
        [`spacer--horizontal`]: horizontal,
        [`spacer--vertical`]: vertical,
      })}
    />
  );
};
