import React from 'react';
import styled from 'styled-components';

import { getSpacing } from '../../constants/Spacing';
import { Typography } from '../Typography';

import { Icon } from './Icon';

interface TitleAndDescription {
  title: string;
  description: string;
}

export interface Props {
  itemArray: TitleAndDescription[];
}

export const ChecklistWithDescriptions: React.FC<Props> = ({ itemArray }) => {
  const listItems = itemArray.map((item) => (
    <div>
      <TextWithIcon>
        <Icon icon="check-square" size="2x"></Icon>
        <Typography variant="p" color="primary" weight="xbold" align="left">
          {item.title}
        </Typography>
      </TextWithIcon>
      <DescriptionText>
        <Typography variant="p" color="primary">
          {item.description}
        </Typography>
      </DescriptionText>
    </div>
  ));

  return <StyledChecklist>{listItems}</StyledChecklist>;
};

const StyledChecklist = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1em;
`;

const TextWithIcon = styled.div`
  display: grid;
  grid-template-columns: ${getSpacing('6x')} 1fr;
  align-items: center;
`;

const DescriptionText = styled.div`
  margin-left: ${getSpacing('1x')};
  grid-area: desc;
`;
