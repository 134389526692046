import { IconName, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { CSSProperties } from 'styled-components';

interface Props {
  icon: string;
  size?: string;
  color?: string;
  inline?: boolean;
}

export const Icon: React.FC<Props> = ({ icon, size, color, inline = true }) => {
  return icon ? (
    <FontAwesomeIcon
      className="resource-icon"
      icon={icon as IconName}
      size={size ? (size as SizeProp) : '4x'}
      color={color ? color : '#324B65'}
      style={inline ? undefined : ({ display: 'block' } as CSSProperties)}
    />
  ) : null;
};
