import React from 'react';
import styled from 'styled-components';

import Breakpoints from '../../../constants/Breakpoints';
import { Typography } from '../../Typography';

export const Funding = () => {
  return (
    <FundingContainer>
      <HeaderText>
        <Typography variant="h1" color="primary">
          Funding
        </Typography>
        <Typography variant="large" color="primary">
          A very important part of the planning process is preparing for your
          finances. How will you pay for your needs? Are you planning to be
          employed, recieve grants, or government cheques? Maybe you are
          planning for a combination of some of all of these options.
        </Typography>
      </HeaderText>
    </FundingContainer>
  );
};

const FundingContainer = styled.div`
  padding: 2em;
  margin: auto;

  @media screen and (min-width: ${Breakpoints.medium}) {
    width: 80%;
  }
`;

const HeaderText = styled.div`
  margin: auto;

  @media screen and (min-width: ${Breakpoints.large}) {
    top: 10em;
  }
`;
