import React from 'react';
import { Element } from 'react-scroll';
import styled from 'styled-components';

import Colors from '../../../constants/Colors';
import { MarketingComponent } from '../../Generic/MarketingComponent';

export const Main = () => {
  return (
    <MainOuterWrapper>
      <MainInnerWrapper>
        <MarketingComponent
          title="Healthcare"
          text="When entering adulthood, many of your health care providers may change. Up until this point you have likely had many of your care providers offered in one place. However, the adult health care system is very different and health care providers are usually found throughout the community."
          image="man-with-crutches"
          imagePosition={'right'}
          ctaText="Find customized resources"
          ctaLink="https://docs.google.com/spreadsheets/d/1PLHTRD0geOmvODDgUTh4KB0fGk1EM7KoQhZSULJxUd0/edit?usp=sharing"
        />
      </MainInnerWrapper>
    </MainOuterWrapper>
  );
};

const MainOuterWrapper = styled(Element)`
  background-color: ${Colors.greyBackgroundColor};
`;

const MainInnerWrapper = styled.div`
  width: 80%;
  margin: auto;
`;
