import React from 'react';
import Mailchimp from 'react-mailchimp-form';
import styled from 'styled-components';

import Breakpoints from '../../constants/Breakpoints';
import Colors from '../../constants/Colors';

export default function Newsletter() {
  return (
    <SurveyPage>
      <SurveyBox className="floating-box">
        <SurveyHeader>
          Sign up for our Newsletter & be the first to know when we officially
          launch!
        </SurveyHeader>

        <MailchimpForm
          crossorigin="anonymous"
          action="https://gmail.us1.list-manage.com/subscribe/post?u=7f4cc2458c86ebb81f10283d4&amp;id=6c76d0b843"
          fields={[
            {
              name: 'NAME',
              placeholder: 'Name',
              type: 'name',
              required: false,
            },
            {
              name: 'EMAIL',
              placeholder: 'Email (required)',
              type: 'email',
              required: true,
            },
          ]}
          messages={{
            sending: 'Sending...',
            success: 'Thank you for subscribing!',
            error: 'An unexpected internal error has occurred.',
            empty:
              'Please ensure to provide and email and that it is correctly formatted.',
            duplicate: 'Too many subscribe attempts for this email address',
            button: 'Submit',
          }}
          className="mailchimp-form"
        />
      </SurveyBox>
    </SurveyPage>
  );
}

const SurveyPage = styled.div`
  background-color: ${Colors.offWhite};
  padding: 10vh 0;
`;

const SurveyBox = styled.div`
  width: 80%;
  max-width: 1000px;
  margin: auto;

  @media only screen and (min-width: ${Breakpoints.small}) {
    width: 60%;
  }
`;

const SurveyHeader = styled.h2`
  max-width: 80%;
  margin: auto;
  padding: 2em 0 0 0;
  font-size: 1.5em;
  text-align: center;

  @media only screen and (min-width: ${Breakpoints.small}) {
    font-size: 2em;
  }
`;

const MailchimpForm = styled(Mailchimp)`
  margin: auto;
  padding: 2em;

  input {
    width: 90%;
    display: block;
    background-color: $grey-highlight;
    border-radius: 5px;
    border: none;
    padding: 1em 0 1em 1em;
    margin: 1em auto;
    font-size: 1em;
  }

  button {
    width: 15em;
    max-width: 80%;
    margin: 1em auto;
    display: block;
    border-radius: 5px;
    background-color: $base-color;
    color: $light-text-color;
    border: none;
    font-size: 1.2em;
    line-height: 3em;
    font-weight: 700;

    &:hover {
      background: $active-highlight;
    }

    &:active {
      background: $base-color;
    }
  }
`;
