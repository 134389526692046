import React from 'react';
import styled from 'styled-components';

import { getSpacing } from '../../constants/Spacing';
import Logo from '../../images/full-logo.png';
import { Typography } from '../Typography';

export const OurSupporters = () => {
  const specialThanksNames = [
    'Abishai Mathews',
    'Alex McEwin',
    'Brendan Wylie-Toal',
    'Emmanuel Orellana',
    'Farzad Rahman',
    'Heather Granger',
    'Maddy Workman',
    'Joanne Duddy',
    'Sarah Christensen',
    "Tammy O'Connor",
  ];

  const styledThanksNames: React.ReactNode[] = [];
  specialThanksNames.forEach((name, index) =>
    styledThanksNames.push(
      <SpecialThanksName key={index}>
        <Typography variant="p" color="primary" weight="thin">
          {name}
        </Typography>
      </SpecialThanksName>,
    ),
  );

  return (
    <OurSupportersContainer>
      <Typography variant="h2" color="primary">
        Our Supporters
      </Typography>
      <Supporters>
        <PrimarySupporter>
          <SupporterLogo src={Logo} alt="Transition Space logo" />
        </PrimarySupporter>
        <SecondarySupporter>
          <SupporterLogo src={Logo} alt="Transition Space logo" />
        </SecondarySupporter>
        <SecondarySupporter>
          <SupporterLogo src={Logo} alt="Transition Space logo" />
        </SecondarySupporter>
      </Supporters>
      <SpecialThanks>
        <>
          <Typography variant="large" color="primary" weight="bold">
            A special thank you to:
          </Typography>

          {styledThanksNames}
        </>
      </SpecialThanks>
    </OurSupportersContainer>
  );
};

const OurSupportersContainer = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 8em;
`;

const Supporters = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const SupporterLogo = styled.img`
  width: 100%;
  padding: 1em;
`;

const PrimarySupporter = styled.div`
  flex: 100%;
`;

const SecondarySupporter = styled.div`
  flex: 40%;
`;

const SpecialThanks = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: center;
`;

const SpecialThanksName = styled.div`
  padding: ${getSpacing('1x')};
`;
