// To be updated in tandem with ./style.module.scss

const Colors = {
  primary: '#0e2d52',
  secondary: '#ff9015',
  tertiary: '#e86826',

  baseColor: '#0e2d52',
  accentColor: '#ff8d0e',
  darkAccentColor: '#ac440f',
  greyBackgroundColor: '#f9f9f9',
  darkGreyBackgroundColor: '#f2f2f2',

  pureWhite: '#ffffff',
  offWhite: '#f8f8ff',
  nearBlack: '#111111',
  greyHighlight: '#c4c4c4',
  modalBackground: '#ffa23b',
  activeHighlight: '#4a617d',
  accentActiveHighlight: '#4a617d',
  focusHighlight: '#479ad5',

  lightTextColor: 'white',
  darkTextColor: 'black',
  disabledTextColor: '#d6d6d6',

  skyBlue: '#246E94',

  inks: ['#000000', '#9f9f9f', '#dddddd', '#ebebeb', '#f7f7f7', '#ffffff'],

  accents: ['#000000', '#9f9f9f', '#dddddd'],
} as const;

export const Color = Colors;

export type ColorKey = keyof typeof Colors;

export type Color = typeof Colors[ColorKey];

export default Color;
