import styled from 'styled-components';

import Color from '../../constants/Colors';
import { getSpacing } from '../../constants/Spacing';
import { Button } from '../Generic/Button';
import { Icon } from '../Generic/Icon';
import { Typography } from '../Typography';

export interface Props {
  icon: string;
  title: string;
  subtitle: string;
  region?: string;
  summary?: string;
  phoneNumber?: string;
  email?: string;
  link: string;
}

const getBodyContentSection = (title: string, content: string) => {
  return (
    <BoldedSection>
      <Typography color="dark" inline={true} weight="bold" variant="p">
        {title}:
      </Typography>{' '}
      <Typography color="dark" inline={true} weight={undefined} variant="p">
        {content}
      </Typography>
    </BoldedSection>
  );
};

export const ResourceCard: React.FC<Props> = ({
  icon,
  title,
  subtitle,
  region,
  summary,
  phoneNumber,
  email,
  link,
}) => {
  return (
    <ResourceCardWrapper>
      {icon && (
        <IconWrapper>
          <Icon icon={icon} size={undefined} color={undefined} inline={false} />
        </IconWrapper>
      )}
      <Typography
        color="primary"
        inline={undefined}
        weight="xbold"
        variant="h4"
      >
        {title}
      </Typography>
      {subtitle && subtitle !== '' && (
        <Typography
          color="primary"
          inline={undefined}
          weight={undefined}
          variant="h5"
        >
          {subtitle}
        </Typography>
      )}

      <BodyContent>
        {region ? getBodyContentSection('Region', region) : null}
        {summary ? getBodyContentSection('Summary', summary) : null}
        {phoneNumber
          ? getBodyContentSection('Phone Number', phoneNumber)
          : null}
        {email ? getBodyContentSection('Email', email) : null}
      </BodyContent>

      <ButtonWrapper>
        <Button
          dest={link}
          display={undefined}
          icon={undefined}
          text="Visit website here"
          variant="white"
          external
        />
      </ButtonWrapper>
    </ResourceCardWrapper>
  );
};

const BoldedSection = styled.div`
  margin: ${getSpacing('1x')};
  & > p {
    margin: 0;
  }
`;

const ResourceCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;
  padding: ${getSpacing('3x')};
  background-color: ${Color.pureWhite};
  border: 2px solid ${Color.primary};
  border-radius: 4px;
`;

const IconWrapper = styled.div`
  margin-bottom: ${getSpacing('3x')};
`;

const BodyContent = styled.div`
  margin-bottom: ${getSpacing('4x')};
`;

const ButtonWrapper = styled.div`
  margin-top: auto;

  div {
    width: auto;
  }
`;
