import { string } from 'prop-types';
import React from 'react';
import Dropd from 'react-dropd';
import styled from 'styled-components';

import Breakpoints from '../../constants/Breakpoints';
import Colors from '../../constants/Colors';
import { Typography } from '../Typography';

export interface Props {
  items: { label: string; key: number }[];
  category: number;
  setCategory: (value: number) => void;
}

export const CategoryNavigation: React.FC<Props> = ({
  items,
  category,
  setCategory,
}) => {
  const styledItems: React.ReactNode[] = [];

  items.forEach((item) => {
    styledItems.push(
      <ResourceCategory
        isActive={category === item.key}
        key={item.label}
        onClick={() => setCategory(item.key)}
        tabIndex={0}
      >
        {category === item.key ? (
          <Typography
            variant="p"
            weight="xbold"
            color="secondary"
            align="center"
          >
            {item.label}
          </Typography>
        ) : (
          <Typography variant="p" weight="sbold" color="primary" align="center">
            {item.label}
          </Typography>
        )}
      </ResourceCategory>,
    );
  });

  return (
    <>
      <ResourceNavDropdown>
        <Dropd
          placeholder="Financial (click here to change resource type)"
          list={items}
          onItemChange={(currentItem, event) => setCategory(currentItem.key)}
        />
      </ResourceNavDropdown>

      <ResourceNav>{styledItems}</ResourceNav>
    </>
  );
};

const ResourceNavDropdown = styled.div`
  text-align: center;

  div {
    min-width: 80%;
  }

  @media screen and (min-width: ${Breakpoints.large}) {
    display: none;
  }
`;

const ResourceCategory = styled.div<{ isActive: boolean }>`
  display: inline-block;
  min-width: 250px;
  width: 15em;
  opacity: 0.8;
  cursor: pointer;

  ${(props) => {
    return props.isActive
      ? `
        position: relative;
        z-index: 0;
        opacity: 1;
        &:before {
          content: '';
          position: absolute;
          z-index: -1;
          left: 30px;
          right: 30px;
          bottom: 0;
          height: 0;
          border: 3px solid ${Colors.accentColor};
          border-radius: 10px;
        }
      `
      : '';
  }}

  &:focus {
    outline: 6px solid ${Colors.focusHighlight};
    outline-offset: 2px;
  }

  @media screen and (min-width: ${Breakpoints.large}) {
    min-width: 220px;
  }
`;

const ResourceNav = styled.div`
  display: none;

  width: fit-content;
  margin: auto;
  text-align: center;
  position: relative;
  z-index: 0;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 30px;
    right: 30px;
    bottom: 0;
    height: 0;
    border: 3px solid ${Colors.disabledTextColor};
    border-radius: 5px;
  }

  @media screen and (min-width: ${Breakpoints.large}) {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
  }
`;
