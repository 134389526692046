import React from 'react';
import styled from 'styled-components';

import Color from '../../../constants/Colors';
import { MarketingComponent } from '../../Generic/MarketingComponent';

export const FullyAssistedLiving = () => {
  return (
    <FALOuterWrapper>
      <FALInnerWrapper>
        <MarketingComponent
          title="Fully Assisted Living"
          text="Fully assisted living options refer to living accomodations where an adult receives 24/7 care, support. This could include live-in or rotational help within your own help, group homes in the community providing ongoing support and care. There are also other types of specialized residences."
          image="women-at-desk"
          imagePosition={'right'}
        />
      </FALInnerWrapper>
    </FALOuterWrapper>
  );
};

const FALOuterWrapper = styled.div`
  background-color: ${Color.greyBackgroundColor};
`;

const FALInnerWrapper = styled.div`
  width: 80%;
  margin: auto;
`;
