import React, { useState, useEffect } from 'react';
import { FaAlignRight } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Breakpoints from '../../constants/Breakpoints';
import Colors from '../../constants/Colors';
import Logo from '../../images/full-logo.png';
import { Typography } from '../Typography';

export default function Navigation() {
  const [visible, setVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setVisible(false);
  }, [location]);

  return (
    <React.Fragment>
      <Navbar>
        <LogoContainer>
          <Link to={'/'}>
            <img
              className="header-logo"
              src={Logo}
              alt="Transition Space logo"
            />
          </Link>
        </LogoContainer>
        <NavLinks visible={visible}>
          <Link to={'/'}>
            <Typography data-text="Home">Home</Typography>
          </Link>

          <a href="https://docs.google.com/spreadsheets/d/1PLHTRD0geOmvODDgUTh4KB0fGk1EM7KoQhZSULJxUd0/edit?usp=sharing">
            <Typography data-text="Explore Resources">
              Explore Resources
            </Typography>
          </a>

          <Link to={'/education'}>
            <Typography data-text="Our Categories">Our Categories</Typography>
          </Link>

          {/* <Link to={'/about-us'}>
            <Typography data-text="About Us">About Us</Typography>
          </Link> */}

          {/* <Link to={'/'}>
            <Typography data-text="Contact Us">Contact Us</Typography>
          </Link> */}

          {/* <Link to={'/login'}>
            <Typography>Log In</Typography>
          </Link> */}
        </NavLinks>
        <NavButton
          onClick={() => {
            setVisible(!visible);
          }}
        >
          <FaAlignRight />{' '}
        </NavButton>
      </Navbar>
    </React.Fragment>
  );
}

const Navbar = styled.div`
  width: 100%;
  position: fixed;
  z-index: 99999;
  top: 0;
  height: 80px;
  background-color: ${Colors.pureWhite};

  display: flex;
  align-items: center;

  @media screen and (min-width: ${Breakpoints.large}) {
    width: 100%;
    clear: both;
    border: none;
    text-align: right;
  }
`;

const LogoContainer = styled.div`
  text-align: left;
  margin-left: 50px;
  float: left;
  img {
    margin: 10px 10px 10px 0;
  }
`;

const NavLinks = styled.div`
  position: absolute;
  width: 100%;
  height: ${(props) => (props.visible ? 'fit-content' : 0)};
  top: 125px;
  overflow: hidden;
  list-style: none;
  margin-left: auto;
  background-color: ${Colors.pureWhite};
  text-align: center;

  a {
    width: 90%;
    display: block;
    text-decoration: none;
    color: ${Colors.primary};
    border-top: 1px solid ${Colors.greyHighlight};
    margin: 1em 0;
    padding-top: 1em;

    &:focus {
      outline: 6px solid ${Colors.focusHighlight};
      outline-offset: 2px;
    }

    p {
      width: 100%;
      height: auto;
      padding: 10px 0 10px 10px;
      margin: 0;

      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      cursor: pointer;
      font-size: 1.25rem;
      font-weight: 500;

      &:active,
      &:hover {
        font-weight: 700;
        text-decoration: underline;
        text-decoration-color: ${Colors.primary};
      }

      &::after {
        display: inline-block;
        content: attr(data-text);
        content: attr(data-text) / '';
        height: 0;
        visibility: hidden;
        overflow: hidden;
        user-select: none;
        pointer-events: none;
        font-weight: 700;

        @media speech {
          display: none;
        }
      }
    }
  }

  @media screen and (min-width: ${Breakpoints.large}) {
    width: auto;
    height: 100%;
    display: inline-flex;
    margin-right: 50px;
    flex-direction: row;
    background: transparent;

    position: relative;
    top: 0;

    a {
      display: block;
      width: fit-content;
      margin: auto 1em;
      border-top: none;

      p {
        text-align: center;
        padding: 0;
      }
    }
  }
`;

const NavButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  margin-right: 50px;
  margin-left: auto;
  font-size: 30px;
  color: ${Colors.baseColor};

  @media screen and (min-width: ${Breakpoints.large}) {
    display: none;
  }
`;
