const SPACING_CONSTANT: number = 8;

const SpacerSizes = {
  '0': 0,
  xs: 1,
  md: 2,
  lg: 3,
  '1x': 1,
  '2x': 2,
  '3x': 3,
  '4x': 4,
  '5x': 5,
  '6x': 6,
  '7x': 7,
  '8x': 8,
  '9x': 9,
  '10x': 10,
} as const;

type SpacerSizesKey = keyof typeof SpacerSizes;

type SpacerValue = number | SpacerSizesKey;

export const getSpacing = (
  spacerValue1: SpacerValue,
  spacerValue2?: SpacerValue,
  spacerValue3?: SpacerValue,
  spacerValue4?: SpacerValue,
): string => {
  function getValue(spacerValue: SpacerValue): number {
    if (spacerValue in SpacerSizes) {
      return SPACING_CONSTANT * SpacerSizes[spacerValue as SpacerSizesKey];
    }
    return spacerValue as number;
  }

  function getPixels(value?: SpacerValue): string {
    return value !== undefined ? ` ${getValue(value)}px` : '';
  }

  const value1 = getPixels(spacerValue1).trim();
  const value2 = getPixels(spacerValue2);
  const value3 = getPixels(spacerValue3);
  const value4 = getPixels(spacerValue4);
  return `${value1}${value2}${value3}${value4}`;
};
