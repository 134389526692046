import React from 'react';
import styled from 'styled-components';

import Breakpoints from '../../constants/Breakpoints';
import Colors from '../../constants/Colors';
import { getSpacing } from '../../constants/Spacing';
import WhatWeDoImage from '../../images/AboutUs/about-us-what-we-do.png';
import { Icon } from '../Generic/Icon';
import { Typography } from '../Typography';

export const WhatWeDo = () => {
  return (
    <WhatWeDoContainer>
      <Typography variant="h2" color="primary">
        What do we do?
      </Typography>

      <ContentContainer>
        <TextContainer>
          <Typography variant="large" color="primary">
            Transition Space seeks to support each individual's successful
            journey and transition to adulthood with physical disabilities. We
            offer support through a variety of services.
          </Typography>

          <TextWithIcon>
            <IconWrapper>
              <Icon icon="file-alt" size="2x" color={Colors.primary} />
            </IconWrapper>
            <Typography variant="large" color="primary" weight="xbold">
              Provide a personalized list of community services that meet
              individual needs
            </Typography>
          </TextWithIcon>

          <TextWithIcon>
            <IconWrapper>
              <Icon icon="running" size="2x" color={Colors.primary} />
            </IconWrapper>
            <Typography variant="large" color="primary" weight="xbold">
              Serve as a resource hub for applicable disability services to help
              youth easily find the services that are right for them
            </Typography>
          </TextWithIcon>

          <TextWithIcon>
            <IconWrapper>
              <Icon icon="heart" size="2x" color={Colors.primary} />
            </IconWrapper>
            <Typography variant="large" color="primary" weight="xbold">
              Share information on major changes that occur in adulthood
            </Typography>
          </TextWithIcon>
        </TextContainer>
        <ImageContainer>
          <StyledImage src={WhatWeDoImage} alt="" />
        </ImageContainer>
      </ContentContainer>
    </WhatWeDoContainer>
  );
};

const WhatWeDoContainer = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 8em;
`;

const ContentContainer = styled.div`
  display: flex;
`;

const TextContainer = styled.div`
  flex: 2;

  @media screen and (min-width: ${Breakpoints.medium}) {
    min-width: 600px;
  }
`;

const TextWithIcon = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-right: ${getSpacing('2x')};
  width: 40px;
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;

  @media screen and (min-width: ${Breakpoints.xLarge}) {
    display: block;
  }
`;

const StyledImage = styled.img`
  object-fit: contain;
`;
