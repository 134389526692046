import React from 'react';
import { scroller } from 'react-scroll';
import styled from 'styled-components';

import Breakpoints from '../../constants/Breakpoints';
import Colors from '../../constants/Colors';
import { getSpacing } from '../../constants/Spacing';
import MainBanner from '../../images/AboutUs/about-us-main.png';
import { Typography } from '../Typography';

export const Main = () => {
  return (
    <MainContainer>
      <CTAContainer>
        <Typography variant="h1" color="primary">
          Who are we?
        </Typography>

        <Typography variant="large" color="primary">
          <strong>
            Transition Space is an online resource hub and community network
          </strong>{' '}
          dedicated to helping youth navigate the adult disability system.
        </Typography>

        <Typography variant="large" color="primary">
          We provide information, resources and community support to help youth
          with disabilities feel empowered and prepared for a positive
          transition from youth to adulthood.
        </Typography>
      </CTAContainer>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  background: url(${MainBanner}) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: bottom;

  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${Colors.lightTextColor};
`;

const CTAContainer = styled.div`
  position: relative;
  max-width: 550px;
  min-height: 400px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  background: ${Colors.pureWhite};
  color: ${Colors.inks[0]};

  border: 2px solid ${Colors.primary};
  border-radius: 5px;

  padding: ${getSpacing('4x', '6x')};
  margin: ${getSpacing('6x')};

  @media screen and (min-width: ${Breakpoints.large}) {
    margin-left: 10em;
  }

  @media screen and (min-width: ${Breakpoints.xLarge}) {
    margin-left: 30em;
  }
`;
