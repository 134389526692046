import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Breakpoints from '../../constants/Breakpoints';
import { getSpacing } from '../../constants/Spacing';
import { Spacer } from '../Layout/Spacer';

import { ResourceCard } from './ResourceCard';

export default function ResourceGrid({ resourceCategory }) {
  // Get resources
  const [resources, setResources] = useState([]);
  useEffect(() => {
    fetch('/api/resources')
      .then((response) => response.json())
      .then((json) => {
        setResources(json);
      });
  }, []);

  // Set Current Resources
  const [styledResources, setStyledResources] = useState([]);
  useEffect(() => {
    let category = '';
    switch (resourceCategory) {
      case 0:
        category = 'finances';
        break;
      case 1:
        category = 'recreation';
        break;
      case 2:
        category = 'healthcare';
        break;
      case 3:
      default:
        category = 'independent living';
    }

    const filteredResources = resources.filter(
      (resource) => resource.category.toLowerCase() === category,
    );
    setStyledResources(
      filteredResources.map((resource, index) => (
        <ResourceCard
          key={index}
          icon={resource?.icon}
          link={resource?.link}
          title={resource?.name}
          subtitle={resource?.subtitle}
          summary={resource?.description}
          email={resource?.email}
          phoneNumber={resource?.phone}
          region={resource?.location}
        />
      )),
    );
  }, [resourceCategory, resources]);

  return (
    <>
      <Spacer vertical size="sm" />
      <StyledGrid>
        {styledResources.map((resource, index) => (
          <StyledGridItem key={index}>{resource}</StyledGridItem>
        ))}
      </StyledGrid>
    </>
  );
}

const StyledGrid = styled.div`
  display: grid;
  width: 90%;
  margin: auto;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(200px, auto);
  grid-gap: ${getSpacing('3x')};

  @media screen and (min-width: ${Breakpoints.medium}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: ${Breakpoints.xLarge}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const StyledGridItem = styled.div`
  height: calc(100% - 24px);
`;
