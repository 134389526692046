import React from 'react';
import styled from 'styled-components';

import Colors from '../../constants/Colors';
import PeopleImage from '../../images/people.png';

export default function PageHeader({ text }) {
  return (
    <StyledPageHeader>
      <h2>{text}</h2>
    </StyledPageHeader>
  );
}

const StyledPageHeader = styled.div`
  height: 200px;
  line-height: 200px;
  text-align: center;
  color: ${Colors.lightTextColor};

  opacity: 0.8;
  background-color: ${Colors.baseColor};
  background-image: url(${PeopleImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  h2 {
    text-shadow: 1px 1px 2px black;
    opacity: 1;
  }
`;
