import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import './button.scss';
import Colors from '../../../constants/Colors';
import { Typography } from '../../Typography';
import { Icon } from '../Icon';

const EXTERNAL_ICON = 'external-link-alt';
const RIGHT_ARROW_ICON = 'long-arrow-alt-right';

interface StyledButtonProps {
  icon?: string;
  text: string;
  external?: boolean;
}

export interface Props extends StyledButtonProps {
  dest?: string;
  display?: 'block' | 'inline-block';
  position?: 'center' | 'right' | 'left';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'white' | 'text';

  // All other props
  [x: string]: any;
}

const getStyledButton: React.FC<StyledButtonProps> = ({
  icon,
  text,
  external,
}) => {
  return (
    <>
      {icon && (
        <IconWrapper>
          <Icon icon={icon} size="2x" />
        </IconWrapper>
      )}
      <Typography variant="large" weight="bold">
        {text}
      </Typography>

      {external && (
        <IconWrapper>
          <Icon icon={EXTERNAL_ICON} size="1x" color={Colors.darkTextColor} />
        </IconWrapper>
      )}
    </>
  );
};

export const Button: React.FC<Props> = ({
  dest,
  display,
  icon,
  text,
  variant,
  external = false,
  position = 'center',
  ...props
}) => {
  if (variant === 'text') {
    return (
      <StyledLink
        role="button"
        tabIndex="0"
        to={dest}
        className="button--variant-text"
        {...props}
      >
        <Typography variant="large" weight="bold" inline>
          {text}
        </Typography>

        {external ? (
          <IconWrapper>
            <Icon icon={EXTERNAL_ICON} size="1x" color={Colors.darkTextColor} />
          </IconWrapper>
        ) : (
          <IconWrapper>
            <Icon
              icon={RIGHT_ARROW_ICON}
              size="1x"
              color={Colors.darkTextColor}
            />
          </IconWrapper>
        )}
      </StyledLink>
    );
  } else if (external) {
    return (
      <a href={dest} target="_blank">
        <StyledButton
          role="button"
          tabIndex={0}
          className={cn({
            [`button--variant-${variant}`]: variant,
            [`button--display-${display}`]: display,
            [`button--position-${position}`]: position,
          })}
          {...props}
        >
          {getStyledButton({ icon, text, external })}
        </StyledButton>
      </a>
    );
  } else {
    return (
      <Link to={dest}>
        <StyledButton
          role="button"
          tabIndex={0}
          className={cn({
            [`button--variant-${variant}`]: variant,
            [`button--display-${display}`]: display,
            [`button--position-${position}`]: position,
          })}
          {...props}
        >
          {getStyledButton({ icon, text, external })}
        </StyledButton>
      </Link>
    );
  }
};

const StyledButton = styled.div`
  width: fit-content;
  border-radius: 4px;
  text-align: center;
  padding: 24px;
  cursor: pointer;

  a {
    text-decoration: none;
  }

  &:focus {
    outline: 6px solid ${Colors.focusHighlight};
    outline-offset: 2px;
  }

  p {
    margin: 0;
    display: inline-block;
  }
`;

const IconWrapper = styled.div`
  display: inline-block;
  margin: 0;
`;

const StyledLink = styled(Link)`
  display: inline-block;
  padding: 0;

  &:focus {
    outline: 6px solid ${Colors.focusHighlight};
    outline-offset: 2px;
  }
`;
