import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Colors from '../../constants/Colors';
import useForm from '../../utils/useForm';
import PageHeader from '../Generic/PageHeader';

const makeQuestion = (
  question,
  questionNumber,
  questionName,
  questionValue,
  setFn,
) => {
  return (
    <SurveyQuestion className="floating-box">
      <p style={{ fontWeight: 'bold' }}>
        {questionNumber + 1}) {question.label}{' '}
      </p>
      <div
        name={questionName}
        value={questionValue}
        onChange={(e) => setFn(e, question.type)}
      >
        {question.options.map((o) => (
          <SurveyQuestionOption key={o}>
            <input
              type={question.type ? 'checkbox' : 'radio'}
              value={o}
              name={questionName}
            />{' '}
            {o}
          </SurveyQuestionOption>
        ))}
      </div>
    </SurveyQuestion>
  );
};

export default function Survey({ history }) {
  // Get questions
  const [questions, setQuestions] = useState([]);
  useEffect(() => {
    fetch('/api/survey')
      .then((response) => response.json())
      .then((json) => {
        setQuestions(json);
      });
  }, []);

  // Form setup
  const [values, handleChange] = useForm();
  const submitForm = (e) => {
    e.preventDefault();

    let allValuesHaveSelected = true;
    for (let k in values) {
      if (!values[k] || values[k].length === 0) {
        allValuesHaveSelected = false;
      }
    }

    if (
      Object.keys(values).length !== questions.length ||
      !allValuesHaveSelected
    ) {
      alert(
        'Please ensure you have filled out each question and re-submit the survey',
      );
    } else {
      history.push('/resources');
      window.location.reload();
    }
  };

  return (
    <>
      <PageHeader text="Take the Survey" />
      <div className="container-large">
        {questions.length !== 0 && (
          <form onSubmit={submitForm}>
            {questions.map((q, index) =>
              makeQuestion(
                q,
                index,
                q.name,
                values[q.name] || '',
                handleChange,
              ),
            )}
            <SurveySubmitButton type="submit" value="Submit" />
          </form>
        )}
      </div>
    </>
  );
}

const SurveyQuestion = styled.div`
  width: 70%;
  margin: 2em auto;
  text-align: left;
`;

const SurveyQuestionOption = styled.label`
  display: block;
  margin: 1em 0;
`;

const SurveySubmitButton = styled.input`
  width: 15em;
  max-width: 80%;
  margin: 1em auto;
  display: block;
  border-radius: 5px;
  background-color: ${Colors.baseColor};
  color: ${Colors.lightTextColor};
  font-weight: 700;
  border: none;
  font-size: 1.2em;
  line-height: 3em;
`;
