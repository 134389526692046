import React from 'react';
import styled from 'styled-components';

import Breakpoints from '../../constants/Breakpoints';
import Building from '../../images/MarketingComponents/building.png';
import ManInSuit from '../../images/MarketingComponents/man-in-suit.png';
import ManWithCrutches from '../../images/MarketingComponents/man-with-crutches.png';
import WavingMan from '../../images/MarketingComponents/waving-man.png';
import WomanOnBench from '../../images/MarketingComponents/woman-on-bench.png';
import WomenAtDesk from '../../images/MarketingComponents/women-at-desk.png';
import WomenWithBags from '../../images/MarketingComponents/women-with-bags.png';
import { Spacer } from '../Layout/Spacer';
import { Typography } from '../Typography';

import { Button } from './Button';

interface ImageProps {
  image:
    | 'waving-man'
    | 'woman-on-bench'
    | 'man-in-suit'
    | 'man-with-crutches'
    | 'building'
    | 'women-at-desk'
    | 'women-with-bags';
  imagePosition?: 'left' | 'right';
}

export interface Props extends ImageProps {
  title: string;
  text: string;
  ctaText?: string;
  ctaLink?: string;
}

const getImageSrc: ({ image: string }) => string = ({ image }) => {
  if (image === 'waving-man') {
    return WavingMan;
  } else if (image === 'woman-on-bench') {
    return WomanOnBench;
  } else if (image === 'man-in-suit') {
    return ManInSuit;
  } else if (image === 'building') {
    return Building;
  } else if (image === 'women-with-bags') {
    return WomenWithBags;
  } else if (image === 'women-at-desk') {
    return WomenAtDesk;
  } else if (image === 'man-with-crutches') {
    return ManWithCrutches;
  } else {
    return '';
  }
};

export const MarketingComponent: React.FC<Props> = ({
  title,
  text,
  image,
  imagePosition = 'right',
  ctaText,
  ctaLink,
}) => {
  return (
    <MarketingComponentContainer image={image} imagePosition={imagePosition}>
      <TypographyContainer>
        <Typography
          variant="h5"
          weight="xbold"
          color="primary"
          align={imagePosition === 'left' ? 'right' : 'left'}
        >
          {title}
        </Typography>
        <Typography
          variant="large"
          weight="regular"
          color="primary"
          align={imagePosition === 'left' ? 'right' : 'left'}
        >
          {text}
        </Typography>
        {ctaText && !ctaLink && (
          <Typography
            variant="large"
            weight="bold"
            color="primary"
            align={imagePosition === 'left' ? 'right' : 'left'}
          >
            {ctaText}
          </Typography>
        )}
        {ctaText && ctaLink && (
          <>
            <Spacer vertical size="xs" />
            <Button
              text={ctaText}
              dest={ctaLink}
              position={imagePosition === 'left' ? 'right' : 'left'}
              variant="secondary"
            />
          </>
        )}
      </TypographyContainer>
      <ImageContainer image={image}>
        <StyledImage
          src={getImageSrc({ image })}
          alt=""
          image={image}
          imagePosition={imagePosition}
        />
      </ImageContainer>
    </MarketingComponentContainer>
  );
};

const MarketingComponentContainer = styled.div<ImageProps>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  padding: 3em 0;

  @media screen and (min-width: ${Breakpoints.small}) {
    ${(props) => {
      return props.imagePosition === 'left'
        ? 'flex-direction: row-reverse'
        : 'flex-direction: row';
    }}
  }
`;

const TypographyContainer = styled.div`
  padding: 0 2em;
`;

const ImageContainer = styled.div<ImageProps>`
  height: 100%;

  ${(props) => {
    let result = '';

    if (props.image === 'woman-on-bench') {
      result += 'width: 55%; margin: -5em 0 -5em auto;';
    }

    return result;
  }}

  @media screen and (min-width: ${Breakpoints.small}) {
    ${(props) => {
      if (props.image === 'waving-man') {
        return 'flex-basis: 100%; min-width: 300px; max-width: 1000px;';
      } else if (props.image === 'woman-on-bench') {
        return `flex-basis: 60%;
        width: auto;
        min-width: 200px;
        margin: auto;

        img {
          display: block;
          margin-left: auto;
        }
        `;
      }

      return '';
    }}
  }
`;

const StyledImage = styled.img<ImageProps>`
  object-fit: cover;
  width: 100%;

  margin-top: 2em;

  @media screen and (min-width: ${Breakpoints.large}) {
    margin-top: 0;
  }

  ${(props) =>
    props.imagePosition === 'left'
      ? `-webkit-transform: scaleX(-1); transform: scaleX(-1);`
      : ''}

  ${(props) => (props.image === 'woman-on-bench' ? 'max-width: 300px;' : '')}
`;
