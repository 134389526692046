import React from 'react';
import styled from 'styled-components';

import Breakpoints from '../../constants/Breakpoints';
import { getSpacing } from '../../constants/Spacing';
import { ContentCard } from '../Generic/ContentCard';
import { Typography } from '../Typography';

export const PressFeatures = () => {
  const stPaulsLogo =
    'https://uwaterloo.ca/stpauls/sites/ca.stpauls/files/styles/body-500px-wide/public/uploads/images/greenhouse_colour_transparent_1.png?itok=Vr_rPhFl';
  const kidsabilityLogo =
    'https://www.kidsability.ca/images/kidsability/logo-kidsability@2x.png';

  const stPaulsLink = 'https://uwaterloo.ca/stpauls/greenhouse';
  const kidsabilityLink = 'https://www.kidsability.ca/';

  return (
    <>
      <PressFeaturesContainer>
        <TitleContainer>
          <Typography variant="h2" color="primary">
            Press Features
          </Typography>
        </TitleContainer>

        <StyledGrid>
          <ContentCard
            imageSrc={stPaulsLogo}
            imageAlt="St Paul's Greenhouse logo"
            title="GreenHouse supported venture"
            subText="Transition Space recognized as contributors to the continued progress of the United Nation's Sustainable Development Goals."
            ctaText="Read more"
            ctaLink={stPaulsLink}
          />
          <ContentCard
            imageSrc={kidsabilityLogo}
            imageAlt="Kidsability Logo"
            title="Transition Space"
            subText="By working with Kidsability and those who have lived experience, Transition Space decided on a tool their clients and their families can directly use."
            ctaText="Read more"
            ctaLink={kidsabilityLink}
          />
        </StyledGrid>
      </PressFeaturesContainer>
    </>
  );
};

const PressFeaturesContainer = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 8em;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${getSpacing('3x')};

  @media screen and (min-width: ${Breakpoints.medium}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
