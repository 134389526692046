import React from 'react';
import { Element } from 'react-scroll';
import styled from 'styled-components';

import Colors from '../../../constants/Colors';
import { MarketingComponent } from '../../Generic/MarketingComponent';

export const Main = () => {
  return (
    <MainOuterWrapper>
      <MainInnerWrapper>
        <MarketingComponent
          title="Independent Living"
          text="Independent Living is an opportunity to explore various living options, based on your needs. There are many options to choose from varying levels of assistance."
          image="building"
          imagePosition={'right'}
          ctaText="Find customized resources"
          ctaLink="https://docs.google.com/spreadsheets/d/1PLHTRD0geOmvODDgUTh4KB0fGk1EM7KoQhZSULJxUd0/edit?usp=sharing"
        />
      </MainInnerWrapper>
    </MainOuterWrapper>
  );
};

const MainOuterWrapper = styled(Element)`
  background-color: ${Colors.greyBackgroundColor};
`;

const MainInnerWrapper = styled.div`
  width: 80%;
  margin: auto;
`;
