import cn from 'classnames';
import React from 'react';

import './typography.scss';

export interface Props {
  children: React.ReactNode;
  align?: 'center' | 'right' | 'left';
  color?: 'light' | 'dark' | 'disabled' | 'primary' | 'secondary';
  inline?: boolean;
  weight?: 'xthin' | 'thin' | 'regular' | 'sbold' | 'bold' | 'xbold';
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'large' | 'title';
  styleRules?: React.CSSProperties;
}

const variantsMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  p: 'p',
  large: 'p',
  title: 'p',
};

/*
  Available colors:
  light => #ffffff
  dark => #000000
  primary => #0e2d52
 */

export const Typography: React.FC<Props> = ({
  children,
  align = 'left',
  color,
  inline = false,
  weight,
  variant,
  styleRules,
  ...props
}) => {
  const Component = (
    variant ? variantsMapping[variant] : 'p'
  ) as keyof JSX.IntrinsicElements;

  return (
    <Component
      className={cn({
        [`typography--align-${align}`]: align,
        [`typography--color-${color}`]: color,
        [`typography--inline`]: inline,
        [`typography--variant-${variant}`]: variant,
        [`typography--weight-${weight}`]: weight,
      })}
      style={{ ...styleRules }}
      {...props}
    >
      {children}
    </Component>
  );
};
