import React from 'react';
import { Element } from 'react-scroll';
import styled from 'styled-components';

import Colors from '../../../constants/Colors';
import { MarketingComponent } from '../../Generic/MarketingComponent';

export const Main = () => {
  return (
    <MainOuterWrapper>
      <MainInnerWrapper>
        <MarketingComponent
          title="Finances"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ad minim veniam, quis nostrud exercitation ullamco laboris"
          image="man-in-suit"
          imagePosition={'right'}
          ctaText="Financial resources"
          ctaLink="https://docs.google.com/spreadsheets/d/1PLHTRD0geOmvODDgUTh4KB0fGk1EM7KoQhZSULJxUd0/edit?usp=sharing"
        />
      </MainInnerWrapper>
    </MainOuterWrapper>
  );
};

const MainOuterWrapper = styled(Element)`
  background-color: ${Colors.greyBackgroundColor};
`;

const MainInnerWrapper = styled.div`
  width: 80%;
  margin: auto;
`;
