import React from 'react';
import styled from 'styled-components';

import Color from '../../../constants/Colors';
import { ChecklistWithDescriptions } from '../../Generic/ChecklistWithDescriptions';
import { Typography } from '../../Typography';

export const DecisionMaking = () => {
  return (
    <DecisionOuterWrapper>
      <DecisionInnerWrapper>
        <Typography variant="h5" color="primary" weight="xbold">
          Decision making
        </Typography>
        <Typography variant="p" color="primary">
          As you reach adulthood, you may be expected to begin making health
          care decisions independently. Here are suggestions to prepare for
          making decisions independently:
        </Typography>
        <ChecklistWithDescriptions
          itemArray={[
            {
              title: 'Healthcare Provider',
              description:
                'Talk to your current health care providers about transitioning into adulthood, and which healthcare services will have to change.',
            },
            {
              title: 'Family Involvement',
              description:
                'Involve family in the process and plan together with them.',
            },
          ]}
        />
      </DecisionInnerWrapper>
    </DecisionOuterWrapper>
  );
};

const DecisionOuterWrapper = styled.div`
  background-color: ${Color.greyBackgroundColor};
`;

const DecisionInnerWrapper = styled.div`
  width: 80%;
  margin: auto;
`;
