import React from 'react';

import { FullyAssistedLiving } from './FullyAssistedLiving';
import { Main } from './Main';
import { PartiallyAssistedLiving } from './PartiallyAssistedLiving';
import { WhoCanHelp } from './WhoCanHelp';

export const EducationIndependentLiving = () => {
  return (
    <React.Fragment>
      <Main />
      <PartiallyAssistedLiving />
      <FullyAssistedLiving />
      <WhoCanHelp />
    </React.Fragment>
  );
};
