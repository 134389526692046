import React from 'react';
import styled from 'styled-components';

import Color from '../../../constants/Colors';
import { MarketingComponent } from '../../Generic/MarketingComponent';

export const PartiallyAssistedLiving = () => {
  return (
    <PALOuterWrapper>
      <PALInnerWrapper>
        <MarketingComponent
          title="Partially Assisted Living"
          text={`Partially assisted living options are for adults that want to live independently, but still require some assistance. Healthcare professionals such as Personal Support Workers and Social Workers provide care in your home or in the community.
          Depending on your needs, you can recieve help with daily personal care, household management, and improving your home's accessibility. For non-medical related needs, like cleaning your house or banking, some service organizations offer to send a professional to your house to provide support.`}
          image="women-with-bags"
          imagePosition={'left'}
        />
      </PALInnerWrapper>
    </PALOuterWrapper>
  );
};

const PALOuterWrapper = styled.div`
  background-color: ${Color.greyBackgroundColor};
`;

const PALInnerWrapper = styled.div`
  width: 80%;
  margin: auto;
`;
