import React from 'react';
import styled from 'styled-components';

import Breakpoints from '../../../constants/Breakpoints';
import Colors from '../../../constants/Colors';
import { getSpacing } from '../../../constants/Spacing';
import { Button } from '../../Generic/Button';
import { Icon } from '../../Generic/Icon';
import { Typography } from '../../Typography';

export const Categories = () => {
  return (
    <CategoriesContainer>
      <Typography variant="h2" weight="xbold" color="primary">
        Browse our Categories
      </Typography>

      <StyledGrid>
        <ResourceBox>
          <Icon icon="coins" size="4x" />
          <Typography variant="h4" weight="xbold" color="primary">
            Finances
          </Typography>
          <Typography variant="large" weight="regular" color="primary">
            Learn about government funding options, private financial
            assistance, and how to plan for the future financially.
          </Typography>
          <Button
            dest="/education/financial"
            text="Learn more"
            variant="text"
          />
        </ResourceBox>

        <ResourceBox>
          <Icon icon="medkit" size="4x" />
          <Typography variant="h4" weight="xbold" color="primary">
            Healthcare
          </Typography>
          <Typography variant="large" weight="regular" color="primary">
            Understand what changes will happen with your healthcare and find
            care providers that will best meet your needs.
          </Typography>
          <Button
            dest="/education/healthcare"
            text="Learn more"
            variant="text"
          />
        </ResourceBox>

        <ResourceBox>
          <Icon icon="house-user" size="4x" />
          <Typography variant="h4" weight="xbold" color="primary">
            Independent living
          </Typography>
          <Typography variant="large" weight="regular" color="primary">
            Learn about different living situations and what each option might
            look like for living independently.
          </Typography>
          <Button
            dest="/education/independent-living"
            text="Learn more"
            variant="text"
          />
        </ResourceBox>

        <ResourceBox>
          <Icon icon="swimmer" size="4x" />
          <Typography variant="h4" weight="xbold" color="primary">
            Recreation
          </Typography>
          <Typography variant="large" weight="regular" color="primary">
            Learn about activities and programs available in your community to
            help with your personal growth and overall health and wellbeing.
          </Typography>
          <Button
            dest="/education/recreation"
            text="Learn more"
            variant="text"
          />
        </ResourceBox>
      </StyledGrid>
    </CategoriesContainer>
  );
};

const CategoriesContainer = styled.div`
  padding: 1em;
  margin: auto;

  @media screen and (min-width: ${Breakpoints.medium}) {
    width: 80%;
  }
`;

const ResourceBox = styled.div`
  background-color: ${Colors.pureWhite};
  border: 2px solid ${Colors.primary};
  border-radius: 4px;
  padding: ${getSpacing('3x')};

  @media screen and (min-width: ${Breakpoints.medium}) {
    padding: ${getSpacing('8x')};
  }
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${getSpacing('3x')};

  @media screen and (min-width: ${Breakpoints.medium}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
