import React from 'react';
import styled from 'styled-components';

import Breakpoints from '../../constants/Breakpoints';
import { getSpacing } from '../../constants/Spacing';
import { PersonCard } from '../Generic/PersonCard';
import { Typography } from '../Typography';

export const MeetTheTeam = () => {
  return (
    <MeetTheTeamContainer>
      <Typography variant="h2" color="primary">
        Meet the team!
      </Typography>

      <StyledGrid>
        <PersonCard
          photo="https://sitiweb.nl/wp-content/uploads/2018/02/avatar.png"
          name="Caleb O. Williams"
          role="Co-founder, Product Design Lead"
        />
        <PersonCard
          photo="https://sitiweb.nl/wp-content/uploads/2018/02/avatar.png"
          name="Charlotte Jane Ogalesco"
          role="Co-founder, Marketing and Creative Lead"
        />
        <PersonCard
          photo="https://sitiweb.nl/wp-content/uploads/2018/02/avatar.png"
          name="Lindsay Krahn"
          role="Co-founder, Content Lead & Project Management"
        />
        <PersonCard
          photo="https://sitiweb.nl/wp-content/uploads/2018/02/avatar.png"
          name="Sarah Berdowski"
          role="Co-founder, Communications and Operations Lead"
        />
        <PersonCard
          photo="https://sitiweb.nl/wp-content/uploads/2018/02/avatar.png"
          name="William Nippard"
          role="Co-founder, Software Lead"
        />
        <PersonCard
          photo="https://sitiweb.nl/wp-content/uploads/2018/02/avatar.png"
          name="Mary Allysa Misola"
          role="User Experience Design Lead"
        />
        <PersonCard
          photo="https://sitiweb.nl/wp-content/uploads/2018/02/avatar.png"
          name="Taryn Beehler"
          role="Digital Marketing Coordinator"
        />
      </StyledGrid>
    </MeetTheTeamContainer>
  );
};

const MeetTheTeamContainer = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 8em;
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${getSpacing('3x')};
  grid-column-gap: ${getSpacing('6x')};

  @media screen and (min-width: ${Breakpoints.medium}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
