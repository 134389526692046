import React from 'react';

import { DecisionMaking } from './DecisionMaking';
import { Main } from './Main';
import { Preparation } from './Preparation';
import { WhoCanHelp } from './WhoCanHelp';

export const EducationHealthcare = () => {
  return (
    <React.Fragment>
      <Main />
      <Preparation />
      <DecisionMaking />
      <WhoCanHelp />
    </React.Fragment>
  );
};
