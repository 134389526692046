import React from 'react';
import styled from 'styled-components';

import Breakpoints from '../../../constants/Breakpoints';
import Colors from '../../../constants/Colors';
import { getSpacing } from '../../../constants/Spacing';
import { Icon } from '../../Generic/Icon';
import { Typography } from '../../Typography';

export const RecreationCategories = () => {
  return (
    <FundingOptionsContainer>
      <TitleContainer>
        <Typography variant="h2" weight="xbold" color="primary" align="center">
          Four resource categories
        </Typography>
        <Typography variant="large" color="primary" align="center">
          Try thinking of one thing from each category you can participate in
          regularly. If there is a category that needs more attention, try to
          come up with ways to incorporate this type of recreation into your
          life.
        </Typography>
      </TitleContainer>

      <StyledGrid>
        <ResourceBox>
          <Icon icon="swimmer" size="4x" />
          <Typography variant="h4" weight="xbold" color="primary">
            Physical
          </Typography>
          <ul>
            <li>
              <Typography variant="large" weight="regular" color="primary">
                Swimming
              </Typography>
            </li>
            <li>
              <Typography variant="large" weight="regular" color="primary">
                Dancing
              </Typography>
            </li>
            <li>
              <Typography variant="large" weight="regular" color="primary">
                Exercise Classes
              </Typography>
            </li>
          </ul>
        </ResourceBox>

        <ResourceBox>
          <Icon icon="users" size="4x" />
          <Typography variant="h4" weight="xbold" color="primary">
            Social
          </Typography>
          <ul>
            <li>
              <Typography variant="large" weight="regular" color="primary">
                Community Social Groups
              </Typography>
            </li>
            <li>
              <Typography variant="large" weight="regular" color="primary">
                Spending time with family and friends
              </Typography>
            </li>
          </ul>
        </ResourceBox>

        <ResourceBox>
          <Icon icon="brain" size="4x" />
          <Typography variant="h4" weight="xbold" color="primary">
            Cognitive
          </Typography>
          <ul>
            <li>
              <Typography variant="large" weight="regular" color="primary">
                Arts and Crafts
              </Typography>
            </li>
            <li>
              <Typography variant="large" weight="regular" color="primary">
                Podcasts
              </Typography>
            </li>
            <li>
              <Typography variant="large" weight="regular" color="primary">
                Video Games or Board Games
              </Typography>
            </li>
          </ul>
        </ResourceBox>

        <ResourceBox>
          <Icon icon="heart" size="4x" />
          <Typography variant="h4" weight="xbold" color="primary">
            Emotional
          </Typography>
          <ul>
            <li>
              <Typography variant="large" weight="regular" color="primary">
                Journaling
              </Typography>
            </li>
            <li>
              <Typography variant="large" weight="regular" color="primary">
                Meditation
              </Typography>
            </li>
          </ul>
        </ResourceBox>
      </StyledGrid>
    </FundingOptionsContainer>
  );
};

const FundingOptionsContainer = styled.div`
  padding: 1em;
  margin: auto;

  @media screen and (min-width: ${Breakpoints.medium}) {
    width: 80%;
  }
`;

const TitleContainer = styled.div`
  width: 60%;
  margin: auto;
  padding: 2em 0;
`;

const ResourceBox = styled.div`
  background-color: ${Colors.pureWhite};
  border: 2px solid ${Colors.primary};
  border-radius: 4px;
  padding: ${getSpacing('3x')};
  color: ${Colors.primary};

  @media screen and (min-width: ${Breakpoints.medium}) {
    padding: ${getSpacing('8x')};
  }
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${getSpacing('3x')};

  @media screen and (min-width: ${Breakpoints.medium}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
