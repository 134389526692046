import React from 'react';
import { Element } from 'react-scroll';
import styled from 'styled-components';

import Colors from '../../constants/Colors';
import { MarketingComponent } from '../Generic/MarketingComponent';

export const CTAs = () => {
  return (
    <CTAsOuterWrapper name="survey-text">
      <CTAsInnerWrapper>
        <MarketingComponent
          title="Find the resources that are right for you."
          text="Explore finance, healthcare, recreation and independent living related services and programs near you. Find the resources that meet your specific needs, future goals and are near you by filling out our curated survey. "
          image="woman-on-bench"
          imagePosition={'right'}
          ctaText="Coming soon..."
        />
        <MarketingComponent
          title="In the meantime, checkout our Resource Catalogue!"
          text="Browse through our Resource Catalogue where you can see all of our general resources Transition Space curated. You can click through the different categories and see what fits your needs!"
          image={'waving-man'}
          imagePosition={'left'}
          ctaText="Explore our resources"
          ctaLink="https://docs.google.com/spreadsheets/d/1PLHTRD0geOmvODDgUTh4KB0fGk1EM7KoQhZSULJxUd0/edit?usp=sharing"
        />
      </CTAsInnerWrapper>
    </CTAsOuterWrapper>
  );
};

const CTAsOuterWrapper = styled(Element)`
  background-color: ${Colors.greyBackgroundColor};
`;

const CTAsInnerWrapper = styled.div`
  width: 100%;
  max-width: 1600px;
  margin: auto;
`;
