import React from 'react';
import { scroller } from 'react-scroll';
import styled from 'styled-components';

import Breakpoints from '../../constants/Breakpoints';
import Colors from '../../constants/Colors';
import { getSpacing } from '../../constants/Spacing';
import People from '../../images/Homepage/homepage-main.png';
import { Button } from '../Generic/Button';
import { Typography } from '../Typography';

export const Main = () => {
  return (
    <MainContainer>
      <CTAOuterContainer>
        <CTAInnerContainer>
          <CTATitleContainer>
            <Typography variant="h1" color="primary" align="center">
              Welcome to Transition Space!
            </Typography>
          </CTATitleContainer>
          <CTATextContainer>
            <Typography variant="large" color="primary" align="center">
              A safe space for youth with disabilities to learn more about the
              adult disability system and successfully transition to adulthood.
            </Typography>
          </CTATextContainer>

          <Button
            dest=""
            text="Get Started"
            variant="secondary"
            style={{ marginTop: '2em' }}
            onClick={() =>
              scroller.scrollTo('survey-text', {
                duration: 1000,
                delay: 100,
                smooth: true,
                offset: -30,
              })
            }
          />
        </CTAInnerContainer>
      </CTAOuterContainer>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  background: url(${People}) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: bottom;

  height: 90vh;

  text-align: center;
  color: ${Colors.lightTextColor};

  @media screen and (min-width: ${Breakpoints.large}) {
    min-height: 1700px;
  }
`;

const CTAOuterContainer = styled.div`
  position: absolute;
  left: 50%;

  @media screen and (min-width: ${Breakpoints.large}) {
    top: 10em;
  }
`;

const CTAInnerContainer = styled.div`
  position: relative;
  left: -50%;
  min-width: 300px;

  background: ${Colors.pureWhite};
  color: ${Colors.inks[0]};

  border: 2px solid ${Colors.primary};
  border-radius: 5px;

  margin: ${getSpacing('3x')};
  padding: ${getSpacing('4x', '2x')};
`;

const CTATitleContainer = styled.div`
  h1 {
    font-size: 2rem;
  }

  @media screen and (min-width: ${Breakpoints.large}) {
    h1 {
      font-size: 3.75rem;
    }
  }
`;

const CTATextContainer = styled.div`
  margin-bottom: auto;

  @media screen and (min-width: ${Breakpoints.large}) {
    margin: auto;
    width: 60%;
  }
`;
