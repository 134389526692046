import React from 'react';
import styled from 'styled-components';

import Color from '../../../constants/Colors';
import { ChecklistWithDescriptions } from '../../Generic/ChecklistWithDescriptions';
import { Typography } from '../../Typography';

export const Preparation = () => {
  return (
    <PreparationOuterWrapper>
      <PreparationInnerWrapper>
        <Typography variant="h5" color="primary" weight="xbold">
          What do I need to prepare for?
        </Typography>
        <ChecklistWithDescriptions
          itemArray={[
            {
              title: 'Disconnected System',
              description:
                'The adult health care system offers that are often disconnected from one another instead of in one building.',
            },
            {
              title: 'Wait Lists',
              description:
                'Prepare ahead of time for wait lists to help ensure that you aren’t left without care when you need it most.',
            },
            {
              title: 'You are in Charge',
              description:
                'You become in charge of making your own health care decisions, unless you assign a designated decision maker.',
            },
          ]}
        />
      </PreparationInnerWrapper>
    </PreparationOuterWrapper>
  );
};

const PreparationOuterWrapper = styled.div`
  background-color: ${Color.greyBackgroundColor};
`;

const PreparationInnerWrapper = styled.div`
  width: 80%;
  margin: auto;
`;
