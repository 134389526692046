const { jsonHeaders } = require('../utils/headers');

const BASE_AUTH_URL = '/api/auth/';

const register = (username, email, password) => {
  const requestOptions = {
    method: 'POST',
    mode: 'cors',
    headers: jsonHeaders(),
    body: JSON.stringify({
      username,
      email,
      password,
    }),
  };

  return fetch(BASE_AUTH_URL + 'signup', requestOptions);
};

const login = (username, password) => {
  const requestOptions = {
    method: 'POST',
    mode: 'cors',
    headers: jsonHeaders(),
    body: JSON.stringify({
      username,
      password,
    }),
  };

  return fetch(BASE_AUTH_URL + 'signin', requestOptions)
    .then((res) => res.json())
    .then((data) => {
      if (data.accessToken) {
        localStorage.setItem('user', JSON.stringify(data));
      }

      return data;
    });
};

const logout = () => {
  localStorage.removeItem('user');
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem('user'));
};

module.exports = {
  register,
  login,
  logout,
  getCurrentUser,
};
