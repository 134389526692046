import React from 'react';

import { MarketingComponent } from '../Generic/MarketingComponent';

export const VolunteerCTA = () => {
  return (
    <>
      <MarketingComponent
        title="Interested in volunteering with us?"
        text="Helps us make Transition Space as great of a platform and community as ever! Spend some time discovering what causes matter most to you, what skills and experiences you want to share and what you can expect for being part of Transition Space."
        image="woman-on-bench"
        ctaText="Contact Us"
        ctaLink="/"
      />
    </>
  );
};
